<template>
  <base-container>
    <template v-slot:header>
      <v-btn
        class="mx-2"
        @click="$router.replace({ name: 'dashboard' })"
        color="accent"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn class="mx-2" @click="add" color="accent">Novo</v-btn>
    </template>
    <v-card :disabled="dataTableLoading">
      <v-layout class="px-5">
        <v-row>
          <v-col class="align-center" cols="12">
            <v-btn
              class="mr-2"
              @click="toDate('today')"
              color="accent"
              outlined="outlined"
              >Hoje</v-btn
            >
            <v-btn @click="toDate('preview')" icon="icon" large="large">
              <v-icon size="20">mdi-chevron-left</v-icon>
            </v-btn>
            <v-menu
              ref="menu"
              v-model="datePickerMenu"
              :close-on-content-click="false"
              transition="fade-transition"
              offset-y="offset-y"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{}">
                <v-btn
                  class="mx-2"
                  @click="showPicker"
                  color="accent"
                  outlined="outlined"
                  >{{ currentDay }}</v-btn
                >
              </template>
              <v-date-picker
                @input="hidePicker"
                color="accent"
                locale="pt-br"
                no-title="no-title"
                v-model="datePicker"
              ></v-date-picker>
            </v-menu>
            <v-btn
              class="mr-2"
              @click="toDate('next')"
              icon="icon"
              large="large"
            >
              <v-icon size="20">mdi-chevron-right</v-icon>
            </v-btn>
            <v-chip
              class="mx-1 mt-1"
              :key="`filter-${i}`"
              @click="closeFilter(item)"
              @click:close="closeFilter(item)"
              close="close"
              small="small"
              v-for="(item, i) in filtersItems"
              ><span class="overline">{{ item.name || item }}</span></v-chip
            >
          </v-col>
        </v-row>
      </v-layout>
      <collection-data-table
        :filters="schedulesFilters"
        :isLoading.sync="dataTableLoading"
        :localFilters.sync="localFilters"
        @item-click="onItemPress"
        @sellout-action="selloutAction"
        type="schedules"
      ></collection-data-table>
    </v-card>
  </base-container>
</template>

<script>
import CollectionDataTable from "@/components/CollectionDataTable";
import BaseContainer from "@/components/BaseContainer";

const SAVED_DATE = "time4pet:saved_date";

export default {
  name: "schedules",
  components: { CollectionDataTable, BaseContainer },

  data: () => ({
    currentDate: new Date(),
    dataTableLoading: false,
    datePickerMenu: false,
    datePicker: "",
    localFilters: {
      animal: {},
      customer: {},
      service: {},
    },
  }),

  mounted() {
    const savedDate = window.localStorage.getItem(SAVED_DATE);
    if (savedDate) {
      this.currentDate = this.$day(savedDate);
      window.localStorage.removeItem(SAVED_DATE);
    }
    this.datePicker = this.$day(this.currentDate).format("YYYY-MM-DD");
  },

  computed: {
    currentDay() {
      const format = this.$vuetify.breakpoint.smAndDown ? "DD MMM" : "DD MMMM";
      return this.$day(this.currentDate).format(format);
    },

    filtersItems() {
      const keys = Object.keys(this.localFilters);
      const items = [];
      keys.forEach((key) =>
        Object.values(this.localFilters[key]).forEach((val) => {
          if (!val.id) {
            items.push({ key, name: val });
          } else {
            items.push({ key, id: val.id, name: val.name });
          }
        }),
      );
      return items;
    },

    schedulesFilters() {
      return {
        schedules: {
          startedAt: this.$day(this.currentDate).startOf("day").toJSON(),
          endedAt: this.$day(this.currentDate).endOf("day").toJSON(),
        },
      };
    },
  },

  methods: {
    onItemPress({ id }) {
      this.$router.push({
        name: "schedule-edit",
        params: { type: "edit", docRef: id },
      });
      window.localStorage.setItem(SAVED_DATE, this.currentDate.toJSON());
    },

    add() {
      this.$router.push({
        name: "schedule-edit",
        params: { type: "add" },
      });
      window.localStorage.setItem(SAVED_DATE, this.currentDate.toJSON());
    },

    closeFilter(v) {
      const filters = JSON.parse(JSON.stringify(this.localFilters));
      delete filters[v.key][v.id || v.name];
      this.localFilters = filters;
    },

    showPicker() {
      this.datePicker = this.$day(this.currentDate).format("YYYY-MM-DD");
      this.datePickerMenu = true;
    },

    hidePicker() {
      this.datePickerMenu = false;
      this.currentDate = this.$day(this.datePicker);
    },

    selloutAction(item) {
      const onClick = () =>
        setTimeout(() => {
          this.$router.push({
            name: "document-edit",
            params: { type: "add", customer: item.customer },
          });
          window.localStorage.setItem(SAVED_DATE, this.currentDate.toJSON());
        }, 100);

      this.$dialog.show(
        "Nova venda",
        "Deseja iniciar uma nova venda para este cliente?",
        [
          { text: "cancelar" },
          { text: "iniciar", style: "destrutive", onClick },
        ],
        { persistent: false },
      );
    },

    toDate(key) {
      switch (key) {
        case "today":
          this.currentDate = this.$day();
          break;
        case "preview":
          this.currentDate = this.$day(this.currentDate).subtract(1, "day");
          break;
        case "next":
          this.currentDate = this.$day(this.currentDate).add(1, "day");
          break;
        default:
          break;
      }
    },
  },
};
</script>
